<template>
  <van-steps class="customSteps" :active="active" :direction="direction" :active-color="activeColor" :inactive-color="inactiveColor">
    <van-step v-for="(item, index) in list" :key="index">
      <template #inactive-icon>
        <slot name="inactive-icon" :index="index"></slot>
      </template>
      <template #active-icon>
        <slot name="active-icon" :index="index"></slot>
      </template>
      <template #finish-icon>
        <slot name="finish-icon"></slot>
      </template>
      <slot :row="item"></slot>
    </van-step>
  </van-steps>
</template>

<script>
import Vue from "vue";
import { Step, Steps } from "vant";
Vue.use(Step);
Vue.use(Steps);
export default {
  name:"customSteps",
  watch: {
    value(newValue, oldValue) {},
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    active: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String,
      default: "vertical",
    },
    activeColor: {
      type: String,
      default: "#07c160",
    },
    inactiveColor: {
      type: String,
      default: "#969799",
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less" scoped>
.customSteps {
  width: 100%;
  height: 100%;
}
</style>
