import { render, staticRenderFns } from "./workOrderServeDetail.vue?vue&type=template&id=bc829f82&scoped=true"
import script from "./workOrderServeDetail.vue?vue&type=script&lang=js"
export * from "./workOrderServeDetail.vue?vue&type=script&lang=js"
import style0 from "./workOrderServeDetail.vue?vue&type=style&index=0&id=bc829f82&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc829f82",
  null
  
)

export default component.exports